.sidebar-toggle {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 20;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 50px;
    border-radius: 50%;
    height: 50px;
    transform: translateX(0);
    transition: transform 0.3s ease;
}

.sidebar-toggle.open {
    background-color: #dc3545;
    border: none;
    transform: translateX(250px);
    transition: transform 0.3s ease;
    top: 15px;
    padding: 3px 10px;
}

.sidebar {
    height: 100vh;
    width: 250px;
    background: #464E80;
    color: #ecf0f1;
    padding: 0;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.nav-perfil{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; 
}

.nav-perfil img {
    margin-top: 20px;
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-bottom: 10px;
}

.nav-perfil p{
    font-weight: 600;
    font-size: 18px;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar-sticky {
    position: sticky;
    top: 0;
}

.nav-item {
    padding: 8px 0;
}

.nav-item:nth-child(1) {
    margin-top: 30px;
}

.nav-item a{
    font-size: 16px;
    color: #fff;
}

.nav-link-left:hover {
    background-color: #34495e;
}

.nav-icon{
    margin-right: 10px;
    font-size: 20px;
}

.nav-link-left.active {
    background-color: #2980b9;
    color: #fff;
    font-weight: 800;
    letter-spacing: 3px;
}

.nav-contato{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    transform: translateX(75%);
}

.nav-contato a{
    background: #31365a;
    color: #fff;
    border: none;
    scale: 1.15;
    width: 100px;
    border-radius: 10px;
    font-size: 15px;
    transition: .3s all ease-in-out;
    text-decoration: none;
    text-align: center;
}

.nav-contato a:hover{
    background: #5666d2;
    scale: 1.2;
    transform: translateY(-4px);
}

.nav-contato a:active{
    background-color: #262c51;
    transition: none;
}