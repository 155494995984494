.link-footer{
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}

.link-footer:hover{
    scale: 1.03;
    letter-spacing: 1.05;
}

.fa-whatsapp{
  margin-right: 20px;
}

.link-whats{
  text-decoration: none;
  color: #fff;
}

.footer {
    text-align: center;
  }
  

  .footer-cnpj{
    padding: 0;
    margin: 0;
  }

  .footer i{
    margin-left: 5px;
    color: #15ff00!important
  }
  @media (max-width: 768px) {
    .footer {
      text-align: center;
    }
  
    .link-footer {
      display: block;
      margin: 0 auto;
    }
  }