/* Container da cidade e banner */
.city {
    height: 33vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column; /* Alinha o banner e o formulário em coluna */
    justify-content: center;
    align-items: center;
    position: relative;
}

/* Contêiner do banner */
.banner-content {
    text-align: center;
    color: #ffffff;
}

/* Texto do banner */
.info-banner {
    margin-bottom: 1rem; /* Espaço entre o banner e o formulário */
}

.info-banner h1 {
    font-size: 55px;
    font-weight: 650;
}

.info-banner p {
    font-size: 20px;
    font-weight: 600;
}

/* Formulário de busca */
.search-form {
    display: flex;
    flex-direction: row; /* Alinha os itens na horizontal */
    align-items: center;
    width: 100%; /* Ocupa toda a largura disponível */
    margin-inline: 20px; /* Centraliza o formulário horizontalmente */
    justify-content: center;
    margin-bottom: 22px;
}

.search-input {
    flex: 1;
    margin-right: 10px; /* Espaço entre os campos */
}

.search-input input {
    z-index: 999;
    width: 400px; /* Ocupa toda a largura disponível dentro do .search-input */
}

.search-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Estilos responsivos */
@media screen and (max-width: 992px) {
    .city{
        height: 30vh;
    }

    .city h1{
        font-size: 30px;
    }
    .city p{
        font-size: 15px;

    }
    .banner-content, .info-banner{
        margin-bottom: 0!important;
    }
    .search-input {
        max-width: 100%; /* Ajusta a largura máxima para telas menores */
    }

    .search-button {
        width: 50px;
        height: 50px;
        margin:auto;
    }

    .search-form {
        flex-direction: column; /* Muda para coluna em telas menores */
        gap: 10px; /* Espaço entre os campos e o botão */
    }

    .search-input input {
        z-index: 999;
        width: 100%; /* Ocupa toda a largura disponível dentro do .search-input */
    }
    
}

/* Seção do banner */
.banner-section {
    position: relative;
    height: 40vh;
}

.icon-location {
    font-size: 90px;
    color: #fff;
}
