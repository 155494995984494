/* navbar.css */
.navbar-custom {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, .5);
    transition: height 0.5s ease-in-out, background-color 0.5s ease-in-out;
    height: 80px;
    background-color: #222;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.bg-navbar {
    background-color: #222;
}

.navbar-custom .navbar-brand {
    color: #f8f9fa; 
    font-size: 1.5rem; 
    font-weight: bold;
    display: flex;
    align-items: center;
}

.navbar-custom .nav-link {
    color: #f8f9fa; 
    font-size: 1.1rem; 
    margin: 0 10px; 
}

.navbar-custom .navbar-toggler {
    border-color: #ffffff; 
    background-color: #fff;
}

.navbar-custom.scrolled {
    background-color: #222;
    height: 100px;
}

.navbar-custom.scrolled .navbar-toggler {
    background-color: #fff;
}

.logo-navbar {
    height: 40px; /* Ajuste conforme necessário */
    margin-right: 15px; /* Ajuste conforme necessário */
    transition: all .5s ease-in-out;
}

.navbar-custom.scrolled .logo-navbar {
    height: 60px;
}

@keyframes rolamento {
    from {
        height: 10px;
    }
    to {
        height: 100px;
    }
}
