.container-anuncio {
    justify-content: center;
    align-items: center;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    padding: 20px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    transition: transform 0.3s;
    width: 100%;
    max-width: 390px;
    height: 550px;
    margin: 20px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
    transform: scale(1.05);
}

.card-title {
    font-weight: bold;
    font-size: 20px;
}

.card-body {
    padding: 20px;
    text-align: center;
    flex-grow: 1;
}

.card-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.old-price {
    text-decoration: line-through;
    color: red;
    font-size: 1.2em;
    margin-bottom: 5px;
}

.new-price {
    font-weight: bold;
    font-size: 1.5em;
    color: green;
}

.card img {
    width: 100px;
    height: auto;
    padding: 20px;
}

.card-text ul {
    list-style: none;
    padding-left: 0;
}

.card-text li {
    margin-bottom: 10px;
    color: #434343;
}

.card .bt-anuncie {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px; 
    padding: 8px;
    background-color: #007bff;
    color: white;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.box-contact {
    width: 500px;
    height: 500px;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 3px double #fff;
    color: #fff;
    border-radius: 25px ;
}

.btn-form{
    padding: 15px 17px;
    border-radius: 50%;
}

.btn-form i{
    font-size: 1.5em;
}

/* .box-form {
    width: 500px;
    height: 600px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: start;
    border: 3px double #fff;
    border-left: none;
    border-radius: 0 25px 25px 0;
    position: relative;
    padding: 20px;
} */

/* .box-form input {
    width: 300px;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1em;
} */

/* .box-form button {
    width: 120px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-align: center;
} */

/* Responsividade para telas médias (tablets) */
@media (max-width: 992px) {
    .card {
        width: 90%;
        max-width: 100%;
        margin: 10px auto;
    }

    .box-form input {
        width: 90%;
    }

    .box-form button {
        right: 50%;
        transform: translateX(50%);
    }
}

/* Responsividade para telas pequenas (celulares) */
@media (max-width: 576px) {
    .card,
    .box-contact,
    .box-form {
        width: 100%;
        max-width: 100%;
    }

    .card img {
        width: 80px;
        padding: 15px;
    }

    .box-form input {
        width: 100%;
    }

    .box-form button {
        width: 100px; /* Ajuste do tamanho do botão para telas pequenas */
        bottom: 20px;
    }
}
