.perfil-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #464E80 50%, #f0f4f8 100%);
    padding: 20px;
}

.perfil-card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 500px;
    padding: 25px;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
    position: relative;
}

.perfil-header {
    margin-bottom: 20px;
    position: relative;
}


.perfil-avatar-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.perfil-avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid #4884AB;
    animation: pulse 1.5s infinite;
}

.perfil-avatar-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.perfil-name {
    font-size: 24px;
    margin-top: 10px;
    color: #333;
    transition: color 0.3s;
}

.perfil-input {
    width: calc(100% - 20px);
    margin-top: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s;

}

.perfil-input:focus {
    border-color: #4884AB;
    outline: none;
}

.perfil-input-name {
    width: calc(70%);
}


.perfil-button {
    padding: 12px 25px;
    background-color: #4884AB;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
}

.perfil-button:hover {
    background-color: #366a8a;
    transform: translateY(-3px);
}

.perfil-logout-button {
    background-color: #e74c3c;
    color: white;
}

.perfil-select {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
    border: 2px solid #ccc;
    width: calc(100% - 50px);
    padding: 10px;
}

.perfil-option {
    text-transform: capitalize;
}

.back-button {
    position: absolute;
    top: 30px;
    left: 30px;
    background-color: rgba(255, 0, 0, 0.7);
    color: #ffffff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 50px;
    height: 50px;
    font-size: 20px;
    transition: background-color 0.2s ease;
}

.back-button:hover {
    background-color: rgba(255, 0, 0, 0.9);
}

.avatar-options {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes popIn {
    from {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}

@keyframes pulse {

    0%,
    100% {
        border-color: #4884AB;
    }

    50% {
        border-color: #366a8a;
    }
}