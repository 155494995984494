.banner-section {
    position: relative;
    height: 55vh;
}

.banner-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    height: 100%;
}

.info-banner {
    text-align: center;
}

.info-banner h1{
    font-size: 55px;
    font-weight: 650;
}

.info-banner p{
    font-size: 20px;
    font-weight: 600;
}

.divider-box {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 200px;
    z-index: 2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-location {
    font-size: 90px;
    color: #fff;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.6));
}