body {
    background: linear-gradient(130deg, #1297c8 60%, #ace0de 50%) ;
    background-repeat: no-repeat;
}

.client-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-inline: 20px;
}

.header-list{
    margin-block: 40px;
    color: #fff;
}
.table {
    margin-left: 200px;
    width: 70%;
    overflow-x: auto;
}
.pesquisa {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 20px;
    margin-right: 40px;
}

.barra-pesquisa {
    padding: 10px;
    border-radius: 10px 0 0 10px !important;
    color: #f0f0f0;
    flex: 1;
    max-width: 300px;
}

.btn-pesquisa {
    padding: 10px 20px;
    border-radius: 0 10px 10px 0;
}

.client-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 20px;
    overflow: hidden;
    margin-right: -100px;
}

.client-table th,
.client-table td {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #6c6c94;
}

.bg-secondary{
    border-bottom: 1px solid #acace3!important;
}

.client-table th {
    background-color: #282851;

}

.client-table tr:hover {
    background-color: #2f2f5b;
}

.checkbox-table {
    border: 1px solid #00000070;
}

.btn-edit,
.btn-delete {
    background-color: transparent;
    cursor: pointer;
    transition: transform 0.2s ease;
    margin-inline: 2px;
}

.btn-edit:hover {
    color: #0099ff!important;
    transform: scale(1.2);
}

.btn-delete:hover {
    background: #ff2020;
    transform: scale(1.2);
}

.btn-delete-selected {
    display: flex;
    align-self: start;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #ff2020;
    color: #fff;
}



.icon-trash {
    margin-right: 10px;
}

.select-operador {
    width: 150px;
    border-radius: 10px;
    margin-right: 50px;
}

.label_sortby {
    margin-left: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-right: 10px;
    display: flex;
    align-self: center;
}

.sortby {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 10px;
    width: 150px;
    font-size: 14px;
    color: #333;
    outline: none;
    transition: border-color 0.2s ease, background-color 0.2s ease;
    cursor: pointer;
}

.sortby:hover {
    border-color: #888;
    background-color: #f0f0f0;
}

.sortby:focus {
    border-color: #4884ab;
    background-color: #fff;
}

.opt-sortby {
    padding: 10px;
}

@media (max-width:768px){
    .pesquisa{
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        gap: 10px;
    }
    .select-operador, .sortby{
        margin-right: 0;
        width: 100%;
    }

    .barra-pesquisa{
        border-radius: 10px!important;
    }

    .btn-pesquisa{
        width: 60px;
        border-radius: 10px!important;
        margin: auto;
    }
    .table{
        width: 100%;
        margin-left: 0;
        overflow-x: auto;
    }
    .client-list-container{
        overflow-y: auto;
    }
    
}