

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    overflow-y: hidden;
    position: relative; 
    background-image: url('../../assets/images/img-login.jpg'); 
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
}

.login-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #00000080;
    border-radius: 20px;
    height: 470px;
    width: 500px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

h2 {
    margin-bottom: 20px;
    color: #ffffff;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
    padding-block: 20px; 
    border-radius: 10px; 
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #fff;
}

input {
    text-align: center;
    width: 400px; 
    max-width: 400px; 
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.login-button {
    width: 100%; 
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px; 
}

.login-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    margin-top: 10px;
}

@media screen and (max-width: 992px) {
    .login-box {
        width: 90%; /* Ajusta a largura da caixa em dispositivos menores */
        max-width: 400px; /* Define um tamanho máximo para manter a responsividade */
        height: auto; /* Ajusta a altura automática para o conteúdo */
    background-color: #00000090;

    } 

    .login-box input{
        width: 300px;
    }
}
